<template>
  <div>
    <div
      v-if="value.plan && value.plan.length > 0"
      class="d-flex flex-column justify-center ma-2 flex-grow-1"
      style="gap: 5px"
      @drop="drop"
      @dragover.prevent
      @dragenter.prevent
    >
      <template v-for="(m, i) in sortedMembers">
        <div class="d-flex">
					<div class="d-flex align-center justify-center mx-1">
						<h4>{{ i + 1 }}</h4>
					</div>
          <v-sheet :color="m.color" class="d-flex flex-grow-1 align-center px-2">
            <div class="d-flex flex-column">
              <h4>{{ m.nombre_completo }}</h4>
            </div>
            <v-icon v-if="m.obs && m.obs != 'undefined'" :title="m.obs ||''">mdi-account-voice</v-icon>
            <v-spacer></v-spacer>
            <h4 class="me-5">{{ m.nombreCategoria }}</h4>
            <v-btn plain icon :ripple="false" @click.stop="changeSchedule(m)" class="noma text-center mx-0 my-0"><v-icon :class="['','special','late'][+m.specialSchedule]" style="transform: scale(1.5,1.5)">mdi-clock-alert</v-icon></v-btn>
            <v-checkbox v-model="m.car" style="transform: scale(1.5,1.5)" hide-details="" :class="'noma text-center mx-5 my-0 ' + (m.car ? 'car' : 'nocar')" off-icon="mdi-car" on-icon="mdi-car"></v-checkbox>
            <v-checkbox v-model="m.conf" style="transform: scale(1.5,1.5)" hide-details="" class="noma text-center mx-5 my-0" color="white"></v-checkbox>
            <v-text-field
              class="mx-5 my-1 nopa"
              style="max-width: 50px"
              filled
              dense
              hide-details
              v-model="m.num"
            />
            <v-btn @click.stop="removeItem(i)" small icon>
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-sheet>
        </div>
      </template>
    </div>

    <div
      style="height: 100%"
      class="d-flex"
      @drop="drop"
      @dragover.prevent
      @dragenter="dragging = true"
      @dragover="dragging = true"
      @dragleave="dragging = false"
    >
      <div
        :style="`border: 1px dashed ${
          dragging ? 'white' : 'grey'
        }; cursor: pointer `"
        class="ma-2 flex-grow-1 d-flex justify-center align-center"
        @click="dialog = true"
        v-ripple
      >
        <v-icon style="pointer-events: none" :disabled="!dragging" large
          >mdi-plus</v-icon
        >
      </div>
    </div>

    <v-dialog max-width="600" v-model="dialog">
      <v-card class="pa-3">
        <v-text-field
          v-model="search"
          item-text="nombre_completo"
          solo
          prepend-inner-icon="mdi-magnify"
          hide-details
          clearable
        >
        </v-text-field>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="item in queriedMembers"
              :key="item.idUnidadEquipo"
              @click.stop="
                addItem(item);
                dialog = false;
              "
            >
              <v-list-item-content class="flex-grow-1">
                <v-list-item-title>
                  {{ item.nombre_completo }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.desc }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-spacer></v-spacer>
              <div class="d-flex flex-column">
                <v-icon>mdi-dots-horizontal</v-icon>
                <v-icon class="mt-n4">mdi-dots-horizontal</v-icon>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    allMembers: Array,
    cuentan: Array,
  },
  data() {
    return {
      dragging: false,
      dialog: false,
      search: null,
      queryTimeout: null,
    };
  },
  computed: {
    queriedMembers() {
      if (this.search == null)
        return this.allMembers.filter((m) => m.used == false).slice(0, 10);

      return this.allMembers
        .filter(
          (m) =>
            m.used == false &&
            m.nombre_completo.toLowerCase().includes(this.search.toLowerCase())
        )
        .slice(0, 10);
    },
    sortedMembers() {
      return this.value.plan.sort((a, b) => {
        return (
          a.ordenCategorias - b.ordenCategorias ||
          a.prioridad - b.prioridad ||
          b.disponibilidad - a.disponibilidad ||
          b.experiencia - a.experiencia
        );
      });
    },
  },
  methods: {
    changeSchedule(emp){
      if (isNaN(emp.specialSchedule)) emp.specialSchedule = 1
      emp.specialSchedule = (emp.specialSchedule + 1) % 3
    },
    drop(evt) {
      const item = JSON.parse(evt.dataTransfer.getData("miembro"));
      var aux = []
      this.cuentan.forEach(e => {
        if (e.cuenta) aux.push(e.idCategoriaEquipo)
      });
      aux.includes(item.idCategoriaEquipo) ? item.num = 1 : item.num = 0
      this.$emit("input", {
        ...this.value,
        plan: [...(this.value.plan || []), item],
      });
    },
    addItem(item) {
      var aux = []
      this.cuentan.forEach(e => {
        if (e.cuenta) aux.push(e.idCategoriaEquipo)
      });
      aux.includes(item.idCategoriaEquipo) ? item.num = 1 : item.num = 0
      this.$emit("input", {
        ...this.value,
        plan: [...(this.value.plan || []), item],
      });
    },
    removeItem(idx) {
      let plan = this.value.plan;
      plan.splice(idx, 1);
      this.$emit("input", {
        ...this.value,
        plan,
      });
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("miembro", JSON.stringify(item));
    },
  },
};
</script>

<style>
.nocar .v-input--selection-controls__input
.v-icon {
  color: rgba(255,255,255,.25) !important;
}
.car .v-input--selection-controls__input
.v-icon {
  color: rgba(255,255,255,1) !important;
}
.late.v-icon {
  color: yellow !important;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
}
.special.v-icon {
  color: rgba(0, 0, 0, 1) !important;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
}
.noma .v-input--selection-controls__input{
  margin: 0 !important;
}
.nopa .v-input__slot{
  padding: 0 !important;
}
.nopa .v-input__slot input{
  text-align: center !important;
}
</style>